<template>
  <div class="h-100">
    <v-row class="h-100 ma-0" no-gutters>
      <v-col cols="4" v-if="!$vuetify.breakpoint.smAndDown"
             class="h-100 d-flex justify-center align-center mr-10 background--yankee-blue">
        <v-img min-width="50px" contain src="@/assets/Logo_S2.png">
        </v-img>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndDown" cols="1"></v-col>
      <v-col class="d-flex align-center pr-16">
        <v-card flat color="body-background">
          <v-row justify="center">
            <v-card-title>
              <v-row class="align-baseline">
                <h3>
                  <span class="grey--text">Welkom bij</span>
                </h3>
                <v-img :width="$vuetify.breakpoint.smAndDown ? '160px' : '320px'" contain src="@/assets/Logo_blauw.png"
                       :class="$vuetify.breakpoint.smAndDown ? 'ml-1' : 'ml-n7'"/>
              </v-row>
            </v-card-title>
          </v-row>
          <v-row>
            <v-card-text class="pt-10">
              <v-row>
                <v-col class="pa-0" v-if="paymentSuccessful">
                  Uw betaling is succesvol verwerkt!
                  <br/>
                  Ga naar de <router-link :to="{name: routeNames.ROOT}" class="text-none">home pagina</router-link> 
                  of ga naar <router-link :to="{name: routeNames.SETTINGS_LICENSE}" class="text-none">instellingen</router-link> om de status van uw abonnement te bekijken.
                </v-col>
                <v-col class="pa-0" v-else>
                  Uw betaling is geannuleerd, wenst u opnieuw te proberen?
                  <router-link :to="{name: paymentRoute}">Klik hier!</router-link>
                  <br/>
                  Mislukt dit opnieuw? Contacteer zeker ons <a href="mailto:info@buildbase.be">Support team</a>
                  om u zo snel mogelijk te assisteren!
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
          <v-row class="ma-16"></v-row>
        </v-card>
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="4"></v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import routeNames from "@/router/RouteNames";
import {traceError} from "@/tracing";

export default {
  name: "FinalizePaymentView.vue",
  data() {
    return {
      routeNames
    }
  },
  created() {
    if (this.$route.query.type == "register" && this.paymentSuccessful) {
      // if url params are filled in, send a purchase event to google analytics
      const source = this.$route.query.source;
      const eventParams = {
        value: this.$route.query.price,
        currency: 'EUR',
        items: [
          {
            name: 'abonnement',
            category: source,
            quantity: this.$route.query.amount,
            price: this.$route.query.price
          }
        ]
      };
      this.$gtag.event("purchase", eventParams);
    }
  },
  computed: {
    paymentSuccessful() {
      switch (this.$route.query.status) {
        case "success":
          return true
        case "failure":
          return false
        case "cancel":
          return false
        default:
          traceError(new Error(`No behaviour defined for ${this.$route.query.status} in paymentSuccessful`))
          return false
      }
    },
    paymentRoute() {
      switch (this.$route.query.type) {
        case "register":
          return routeNames.PAYMENT
        case "seats":
          return routeNames.SETTINGS_LICENSE
        case "extension":
          return routeNames.SETTINGS_LICENSE
        case "trial_to_paid":
          return routeNames.TRIAL_TO_PAID
        default:
          if (this.$route.query.type)
          {
            traceError(new Error(`No behaviour defined for ${this.$route.query.type} in paymentRoute`))
          }
          return routeNames.ROOT
      }
    }
  },
}
</script>